<template>
    <div class="detalis-container">
        <HeaderBar ref="header-bar" type="2" :lock="true" />
        <div class="detalis-ctx">
            <div class="rect-view section-ctx">
                <div class="ctx-1 wow fadeInUp"></div>

                <div class="details-content">
                    <div class="html-title"> {{title}}</div>
                    <div class="html-test" v-html="html"></div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
import HeaderBar from "@/components/HeaderBar.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        HeaderBar,
        Footer,
    },
    data() {
        return {
            html: "",
            title:''
        };
    },
    created() {
        _hmt.push(['_trackPageview', '/pc-文章详情']);

        Toast.loading({
            forbidClick: true,
        });

        $.get(
            `https://www.hzyupin.com/index/News/get?id=${this.$route.query.id}`,
            (res) => {
                // this.list = res.data.data;
                // console.log(this.list);
                // console.log(res);
                this.html = res.data.content;
                this.title = res.data.title;
            }
        );

        Toast.clear();

        this.$nextTick(() => {
            new WOW().init({
                offset: 10,
            });

            // this.$refs["header-bar"].openState = true;
            this.$refs["header-bar"].isMove = true;

            // isMove
        });
    },
};
</script>

<style lang="scss">
.detalis-container {
    max-width: 1920px;
    margin: 0 auto;
    background-image: url(../../public/images/31.jpg);
    .ctx-1 {
        width: 1200px;
        height: 125px;
        margin: 176px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/23.png);
    }
    .details-content {
        margin-top: 60px;
        min-height: 300px;
        margin-bottom: 60px;
        padding: 40px 60px;
        box-sizing: border-box;
        background-color: white;
    }
    .rect-view {
        overflow: hidden;
    }
}
.detalis-ctx {
    min-height: calc(100vh - 200px);
}
.html-test {
    line-height: 30px;
}
.html-title {
    margin-bottom: 60px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
}
</style>